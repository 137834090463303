import React from 'react';
import { useSelector } from 'react-redux';
import clsx from 'clsx';
import { getVariantProductInfoSelector } from '@modules/products/core/selectors';
import { UpdateSubscriptionItem } from '@modules/subscriptions/components/subscription-lifecycle/modals/update-subscription/UpdatedSubscriptionItem';

interface SubscriptionReactivationOrderItemsProps {
  totalSachetQuantity: number;
}

export const SubscriptionReactivationOrderItems: React.FC<
  SubscriptionReactivationOrderItemsProps
> = ({ totalSachetQuantity }) => {
  const productInfo = useSelector(getVariantProductInfoSelector);

  return (
    <div className="flex justify-between subscription-reactivation-order-items max-gap-[3.1rem] items-baseline xl:items-center">
      <div className="hidden xl:block">
        <UpdateSubscriptionItem
          src="media/svg/product/pouch-icon.svg"
          alt="package"
          className="w-[2.2rem] !flex-col"
        >
          <div className="items-center md:flex md:flex-col">
            <ul
              className={clsx(
                `flex mx-auto justify-center${
                  productInfo?.sachets?.filter((sachet: ISachet) => sachet.quantity > 0).length ===
                  1
                    ? 'justify-center'
                    : ''
                }`,
              )}
            >
              {productInfo?.sachets
                ?.filter((sachet: ISachet) => sachet.quantity > 0)
                .sort((a: ISachet, b: ISachet) => a.weight.value - b.weight.value)
                .map((sachet: ISachet, index: number, array: ISachet[]) => (
                  <React.Fragment key={index}>
                    {index === 0 && (
                      <li className="sachet-list" data-key={sachet.type}>
                        {sachet.weight.value}g
                      </li>
                    )}
                    {index === array.length - 1 && array.length > 1 && (
                      <>
                        <span className="mx-1 -mt-1">-</span>
                        <li className="sachet-list" data-key={sachet.type}>
                          {sachet.weight.value}g
                        </li>
                      </>
                    )}
                  </React.Fragment>
                ))}
            </ul>
            <div className="font-medium text-[1.4rem] leading-[1.75rem]">Packungsgröße</div>
          </div>
        </UpdateSubscriptionItem>
      </div>

      <UpdateSubscriptionItem
        src="media/svg/product/box-icon-with-pouches.svg"
        xlSrc="media/svg/product/box-icon.svg"
        alt="box"
        className="w-[3.9rem] !flex-col"
      >
        <div className="items-center md:flex md:flex-col">
          <ul
            className={clsx(
              `flex ${
                productInfo?.sachets?.filter((sachet: ISachet) => sachet.quantity > 0).length === 1
                  ? 'justify-center'
                  : ''
              }`,
            )}
          >
            {productInfo?.sachets
              ?.filter((sachet: ISachet) => sachet.quantity > 0)
              .sort((a: ISachet, b: ISachet) => a.weight.value - b.weight.value)
              .map((sachet: ISachet, index: number, array: ISachet[]) => (
                <React.Fragment key={index}>
                  {index === 0 && (
                    <li className="flex sachet-list" data-key={sachet.type}>
                      {totalSachetQuantity}
                      <span className="xl:hidden"> x {sachet.weight.value}</span>
                    </li>
                  )}
                  {index === array.length - 1 && array.length > 1 && (
                    <>
                      <span className="mx-1 -mt-1 xl:hidden">-</span>
                      <li className="sachet-list xl:hidden" data-key={sachet.type}>
                        {sachet.weight.value}g
                      </li>
                    </>
                  )}
                  {index === 0 && array.length === 1 && (
                    <li className="flex sachet-list" data-key={sachet.type}>
                      {totalSachetQuantity}
                      <span className="xl:hidden"> x {sachet.weight.value}g</span>
                    </li>
                  )}
                </React.Fragment>
              ))}
          </ul>
          <div className="font-medium text-[1.4rem] leading-[1.75rem]">
            <span className="xl:hidden">In der Box</span>
            <span className="hidden xl:block">Packungen pro Box</span>
          </div>
        </div>
      </UpdateSubscriptionItem>

      <UpdateSubscriptionItem
        src="media/svg/product/calendar.svg"
        alt="package"
        className="w-[3rem] !flex-col md:!mb-[0.7rem]"
      >
        <div className="items-center md:block md:flex-col">
          <ul className="flex items-center justify-center gap-2 text-center md:block">
            <li className="sachet-list"> Tage {productInfo?.options?.cycle}</li>
          </ul>
          <div className="font-medium text-[1.4rem] leading-[1.75rem]">Frisches Futter</div>
        </div>
      </UpdateSubscriptionItem>

      <UpdateSubscriptionItem
        src={`media/svg/product/${
          productInfo?.options?.pension === 'V' ? 'voll-pension' : 'halb-pension'
        }.svg`}
        alt="pension"
        className="w-[3rem] md:!mb-[0.7rem]"
      >
        <div className="items-center md:flex md:flex-col">
          <ul className="flex items-center justify-center gap-2 text-center md:block">
            <li className="sachet-list">
              {productInfo?.options?.pension === 'V' ? 'Vollpension' : 'Halbpension'}
            </li>
          </ul>
          <div className="font-medium text-[1.4rem] leading-[1.75rem]">Portionsgröße</div>
        </div>
      </UpdateSubscriptionItem>
    </div>
  );
};
