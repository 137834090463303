import { useSelector } from 'react-redux';
import clsx from 'clsx';
import {
  getDefaultSubscriptionSelector,
  getSubscriptionsSelector,
} from '@modules/subscriptions/core/selectors';

import { KTSVG, toAbsoluteUrl } from '@helpers/index';
import { useRef, useState } from 'react';
import { useClickOutside } from 'src/shared/hooks/useClickOutside';
import { useSubscriptions } from '@modules/subscriptions/hooks/useSubscriptions';

export const SubscriptionsListDropdown = () => {
  const defaultSubscription = useSelector(getDefaultSubscriptionSelector);
  const subscriptions = useSelector(getSubscriptionsSelector);

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const { switchDefaultSubscription } = useSubscriptions();

  const ref = useRef<HTMLDivElement | null>(null);

  useClickOutside(ref, () => {
    setIsDropdownOpen(false);
  });

  const switchSubscription = (subscription: ISubscription) =>
    switchDefaultSubscription(subscription);

  const sortingOrder: { [key in ISubscription['status']]: number } = {
    ACTIVE: 1,
    PAUSED: 2,
    CANCELLED: 3,
  };

  const subscriptionsSortedByActivity = [...subscriptions].sort(
    (a, b) => sortingOrder[a.status] - sortingOrder[b.status],
  );

  const subscriptionsWithPets = subscriptionsSortedByActivity.filter(
    (subscription) => subscription?.pet && subscription?.pet.name,
  );

  const handleDropdownToggle = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  return (
    <div ref={ref} className="relative">
      <button
        onClick={handleDropdownToggle}
        className={clsx('subscription-dropdown', {
          'subscription-dropdown-active': defaultSubscription?.status === 'ACTIVE',
          'subscription-dropdown-paused': defaultSubscription?.status === 'PAUSED',
          'subscription-dropdown-cancelled': defaultSubscription?.status === 'CANCELLED',
        })}
      >
        <span className="subscription-dropdown-pet-name">
          {defaultSubscription ? defaultSubscription?.pet?.name : 'Meine Hunde'}
        </span>
        <span className={`${!isDropdownOpen ? '' : 'rotate-180'} transition-transform`}>
          <KTSVG path={toAbsoluteUrl('/media/svg/general/down-arrow.svg')} />
        </span>
      </button>

      {isDropdownOpen && (
        <div className="subscription-dropdown-list">
          {subscriptionsWithPets.map((subscription: ISubscription, index) => (
            <button
              className={clsx(
                'subscription-dropdown-item w-full',
                {
                  'bg-primary !text-white':
                    subscription?.id === defaultSubscription?.id &&
                    subscription?.status === 'ACTIVE',
                  '!bg-[#F5E2BE] !text-white':
                    subscription?.id === defaultSubscription?.id &&
                    subscription?.status === 'PAUSED',
                  'bg-[#c1c1c1] !text-white':
                    subscription?.id === defaultSubscription?.id &&
                    subscription?.status === 'CANCELLED',
                },
                {
                  'text-primary bg-white hover:bg-primary hover:text-white':
                    subscription?.id !== defaultSubscription?.id &&
                    subscription?.status === 'ACTIVE',
                  'text-[#EDBF69] hover:bg-light-yellow hover:text-white':
                    subscription?.status === 'PAUSED',
                  'text-[#c1c1c1] hover:bg-[#c1c1c1] hover:text-white':
                    subscription?.status === 'CANCELLED',
                },
                {
                  'rounded-tl-[8px] rounded-tr-[8px]': index === 0,
                },
                {
                  'opacity-50 hover:opacity-100':
                    subscription?.id !== defaultSubscription?.id &&
                    subscription?.status === 'ACTIVE',
                },
              )}
              key={subscription.id}
              onClick={() => {
                switchSubscription(subscription);
                handleDropdownToggle();
              }}
            >
              <div className="font-bold">
                <span
                  className={clsx(
                    '!hover:opacity-100',
                    {
                      'hover:bg-primary hover:opacity-100 hover:text-white':
                        subscription?.status === 'ACTIVE',
                      'bg-[#F5E2BE]': subscription?.status === 'PAUSED',
                      'hover:bg-[#c1c1c1] hover:text-black': subscription?.status === 'CANCELLED',
                    },
                    {
                      'hover:text-white':
                        subscription?.status !== 'ACTIVE' &&
                        subscription?.status !== 'PAUSED' &&
                        subscription?.status !== 'CANCELLED',
                    },
                  )}
                >
                  <div>{subscription?.pet?.name ?? '-'}</div>
                </span>
              </div>
            </button>
          ))}

          <a
            target="_blank"
            className="subscription-dropdown-item w-full flex items-center gap-2 bg-white text-primary hover:bg-primary hover:text-white rounded-bl-[8px] rounded-br-[8px] !text-[1.3rem] !leading-[2.2rem] subscription-add-new"
            href="https://futter.hellobello.at"
            rel="noreferrer"
          >
            <KTSVG path={toAbsoluteUrl('/media/svg/general/plus-no-bg.svg')} />
            Neuer Hund
          </a>
        </div>
      )}
    </div>
  );
};
