import { Modal } from '@components/index';
import { KTSVG, toAbsoluteUrl } from '@helpers/index';
import { getCustomerSelector } from '@modules/customer/core/selectors';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

export const HolidayNotification = () => {
  const customer = useSelector(getCustomerSelector);

  const [isModalVisible, setIsModalVisible] = useState(false);

  useEffect(() => {
    const lastShown = localStorage.getItem('holidayModalLastShown');
    const currentTime = Date.now();
    const sevenDaysInMilliseconds = 7 * 24 * 60 * 60 * 1000;

    if (
      (!lastShown || currentTime - parseInt(lastShown, 10) > sevenDaysInMilliseconds) &&
      customer?.tags?.includes('X-mas2024')
    ) {
      setIsModalVisible(true);
      localStorage.setItem('holidayModalLastShown', currentTime.toString());
    }
  }, [customer]);

  const handleClose = () => setIsModalVisible(false);

  if (!customer?.tags?.includes('X-mas2024')) return null;

  return (
    <div className="row gy-5 g-xl-8">
      <div className="mb-20">
        <Modal isOpen={isModalVisible} handleClose={handleClose}>
          <div className="flex justify-center text-3xl">
            <div className="xl:px-[8rem] xl:pt-0 pb-[5rem] px-[2rem] text-[2.4rem] leading-[1.4] md:max-w-[100rem]">
              <div className="w-[20rem] mx-auto -my-[3rem]">
                <KTSVG path={toAbsoluteUrl('/media/svg/general/holiday.svg')} />
              </div>

              <p className="mb-4">
                Wir packen dir in deine aktuelle Bestellung{' '}
                <span className="font-extrabold">2 zusätzliche Packungen</span> Frischfutter
                kostenlos dazu.
              </p>
              <p>
                Damit hat deine Fellnase ausreichend Futter, falls sich die Zustellung während der
                Weihnachtsfeiertage mal verzögern sollte.
              </p>
            </div>
          </div>
        </Modal>
      </div>
    </div>
  );
};
