import { useSelector } from 'react-redux';
import {
  getDefaultSubscriptionSelector,
  getFlowBannerSelector,
  getFlowBannerTextSelector,
} from '@modules/subscriptions/core/selectors';
import { getCurrentOrderSelector } from '@modules/order-schedule/core/selectors';
import { useViewport } from '@constants/viewportBreakpoints';
import { decodeHtmlEntities } from '@helpers/decodeHtmlEntities';
import { WelcomeMessage } from './components/welcome-message/WelcomeMessage';
import { UpcomingOrders } from './components/upcoming-orders/UpcomingOrders';
import { RecipeOverview } from './components/recipe-overview/RecipeOverview';
import { Supplements } from './components/supplements/Supplements';
import { CustomerTag } from '@modules/dashboard/components/customer-tag/CustomerTag';
import { getCustomerSelector } from '@modules/customer';
import { SubscriptionReactivation } from './components/subscription-reactivation/SubscriptionReactivation';
import { SubscriptionList } from './components/subscription-list/SubscriptionList';
import { RewardBanner } from './components/reward-banner/RewardBanner';
import { UpcomingReward } from './components/reward-banner/UpcomingReward';
import { UpcomingRewardFlyout } from './components/reward-banner/UpcomingRewardFlyout';
import { NoRewardBanner } from './components/reward-banner/NoRewardBanner';
import { RafLink } from './components/raf-link/RafLink';
import { HolidayNotification } from './components/holiday-modal/HolidayNotification';

export const DashboardPage = () => {
  const order = useSelector(getCurrentOrderSelector);
  const customer = useSelector(getCustomerSelector);
  const flowBanner = useSelector(getFlowBannerSelector);
  const flowBannerText = useSelector(getFlowBannerTextSelector);
  const isRewarded = flowBanner?.[0]?.isRewardReceived;
  const decodedFlowBannerText = decodeHtmlEntities(flowBannerText);
  const defaultSubscription = useSelector(getDefaultSubscriptionSelector);
  const isActive = defaultSubscription?.status === 'ACTIVE';
  const { isXs } = useViewport();

  return (
    <>
      <CustomerTag />
      <HolidayNotification />
      <div className="dashboard-page w-full md:col-span-2 md:grid md:grid-cols-12 lg:grid-cols-2 gap-[1.2rem] items-center">
        <div className="md:col-span-5 lg:col-span-1">
          <h1 className="dashboard-page-title">
            <span>Willkommen</span> in deinem Pfotenportal
            {customer?.firstName ? `, ${customer?.firstName}` : null}!
          </h1>
        </div>
        {isActive && (
          <div className="hidden md:block md:col-span-7 lg:col-span-1">
            <RafLink />
          </div>
        )}
      </div>

      {!isActive && (
        <div className="grid grid-cols-1 mb-[1.2rem]">
          <SubscriptionReactivation />
        </div>
      )}

      <div className="dashboard-page-wrapper grid grid-cols-1 md:grid-cols-2 mb-[1.5rem] gap-[1.2rem]">
        {isActive && (
          <div className="w-full md:col-span-2 md:grid md:grid-cols-12 lg:grid-cols-2 gap-[1.2rem]">
            <div className="md:col-span-5 lg:col-span-1">
              <WelcomeMessage />
            </div>
            <div className="relative px-[1rem] md:px-0 md:col-span-7 lg:col-span-1">
              {isRewarded ? (
                <RewardBanner />
              ) : decodedFlowBannerText?.trim() ? (
                <>
                  <UpcomingReward />
                  {isXs && (
                    <UpcomingRewardFlyout
                      isModalOpen={false}
                      toggleModal={() => {}}
                      discountPercentage=""
                      flowBannerText={null}
                    />
                  )}
                </>
              ) : (
                <NoRewardBanner />
              )}
            </div>
          </div>
        )}
        {isActive && (
          <>
            <div className="dashboard-page-recipes md:col-span-1">
              <RecipeOverview />
            </div>
            <div className="dashboard-page-orders md:col-span-1">
              <UpcomingOrders order={order} />
            </div>
          </>
        )}

        {/* Subscription List and Supplements */}
        <div className="dashboard-page-subscription-list w-full md:col-span-2 md:grid md:grid-cols-12 lg:grid-cols-2 gap-[1.2rem] flex flex-col h-full">
          <div className="flex-grow h-full md:col-span-6 lg:col-span-1">
            <SubscriptionList />
          </div>
          <div className="dashboard-page-supplements md:col-span-6 lg:col-span-1">
            <Supplements />
          </div>
        </div>
      </div>
    </>
  );
};
