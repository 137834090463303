import { useDispatch, useSelector } from 'react-redux';
import {
  getDefaultSubscriptionSelector,
  getPetSelector,
} from '@modules/subscriptions/core/selectors';
import { reactivateSubscriptionRequest } from '@modules/subscriptions/core/actions';
import { Headline } from '@components/index';
import { SubscriptionReactivationInfo } from '../SubscriptionReactivationInfo';
import { SubscriptionReactivationDate } from '../SubscriptionReactivationDate';
import { getSubscriptionReactivate } from '@constants/subscription';

export const SubscriptionReactivate = () => {
  const dispatch = useDispatch();
  const defaultSubscription = useSelector(getDefaultSubscriptionSelector);
  const pet = useSelector(getPetSelector);

  const subscriptionReactivate = getSubscriptionReactivate(pet);

  const handleReactivateSubscription = () => {
    dispatch(
      reactivateSubscriptionRequest({
        id: defaultSubscription?.id,
      }),
    );
  };

  return (
    <div className="subscription-reactivation">
      <Headline>
        <p>ABO WIEDER AKTIVIEREN</p>
      </Headline>

      <div className="md:flex gap-[1.2rem]">
        <SubscriptionReactivationInfo
          {...subscriptionReactivate}
          onReactivateClick={handleReactivateSubscription}
        />

        <div className="hidden md:block subscription-reactivation-date">
          <SubscriptionReactivationDate />
        </div>
      </div>
    </div>
  );
};
