import { useRef, useState } from 'react';
import { FormattedDate } from 'react-intl';
import { useClickOutside } from 'src/shared';
import { KTSVG, toAbsoluteUrl } from '@helpers/index';
import { Modal } from '@components/index';
import { calcDeliveryDate } from '@modules/subscriptions/utils/calcDeliveryDate';
import { SubscriptionReactivationModal } from './reactivation-modal/SubscriptionReactivationModal';

export const SubscriptionReactivationDate = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const ref = useRef<HTMLDivElement | null>(null);
  const today = new Date().toISOString();

  useClickOutside(ref, () => {
    setIsModalOpen(false);
  });

  const [dateFrom, dateTo] = calcDeliveryDate(today);
  const handleClose = () => setIsModalOpen(false);

  const handleModalToggle = () => {
    setIsModalOpen(!isModalOpen);
  };

  return (
    <div className="px-[2rem] py-[3rem] xl:p-[3rem] relative">
      <div className="subscription-reactivation-calendar">
        <button className="subscription-reactivation-calendar-date">
          <p className="">Bestelldatum</p>

          <FormattedDate value={today} year="numeric" month="short" day="2-digit" />
        </button>
        <button className="subscription-reactivation-calendar-delivery">
          <p className="">Lieferdatum</p>
          <FormattedDate value={dateFrom} day="2-digit" /> -{' '}
          <FormattedDate value={dateTo} year="numeric" month="short" day="2-digit" />
        </button>
      </div>
      <div>
        <img
          alt="subscription-reactivation"
          src={toAbsoluteUrl('/media/img/subscription/subscription-reactivation.png')}
          className="relative z-50"
        />
      </div>

      <div className="subscription-reactivation-action">
        <button className="flex items-center gap-2" onClick={handleModalToggle}>
          <KTSVG path={toAbsoluteUrl('/media/svg/general/edit.svg')} />
          Versand & Zahlung
        </button>
        {isModalOpen && (
          <Modal isOpen={true} handleClose={handleClose}>
            <SubscriptionReactivationModal />
          </Modal>
        )}
      </div>

      <div className="subscription-reactivation-fade-left">BESTELLDATUM</div>
      <div className="subscription-reactivation-fade-right">LIEFERDATUM</div>
    </div>
  );
};
